import { get, set } from '@/helper/localStorage'

export default {
  namespaced: true,
  state: {
    loadingAddress: false,
    loadingDistrict: false,
    loadingSubmit: false,
    addressList: get('addressList') || [],
    districtsList: get('districtsList') || [],
    lastPage: 1,
    currentPage: 1,
  },
  getters: {
    addressList: state => {
      if (!state.addressList) {
        return undefined
      }

      return state.addressList
    },
    districtsList: state => {
      if (!state.districtsList) {
        return undefined
      }

      return state.districtsList
    },
  },
  mutations: {
    SET_ADDRESS_LIST(state, val) {
      // If it's not the first page, concatenate the new data to the existing data
      if (state.currentPage > 1) {
        state.addressList = state.addressList.concat(val)
      } else {
        // If it's the first page, replace the existing data with the new data
        state.addressList = val
      }
      set('addressList', state.addressList)
    },
    SET_DISTRICTS_LIST(state, val) {
      set('districtsList', val)
      state.districtsList = val
    },
    SET_DEFAULT_ADDRESS(state, val) {
      state.addressList = state.addressList.map(address => (address.id === val.id ? { ...address, is_default: true } : { ...address, is_default: false }))
    },
    SET_REGISTRY_ADDRESS(state, val) {
      state.addressList = state.addressList.map(address => (address.id === val.id ? { ...address, is_registry_address: true } : { ...address, is_registry_address: false }))
    },
    SET_UPDATE_ADDRESS(state, val) {
      state.addressList = state.addressList.map(address => (address.id === val.id ? val : address))
    },
    SET_DELETE_ADDRESS: (state, id) => {
      state.addressList = state.addressList.filter(address => address.id !== id)
    },
    SET_LOADING_ADDRESS(state, val) {
      state.loadingAddress = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
    SET_LOADING_DISTRICT(state, val) {
      state.loadingDistrict = val
    },
    SET_LAST_PAGE(state, val) {
      state.lastPage = val
    },
    SET_CURRENT_PAGE(state, val) {
      state.currentPage = val
    },
  },
  actions: {
    getListAddress({ commit }, { id, page, limit }) {
      commit('SET_LOADING_ADDRESS', true)
      return this._vm.$http.get(`/v1/admin/users/${id}/addresses?page=${page}&limit=${limit}`)
        .then(res => {
          commit('SET_ADDRESS_LIST', res.data.data)
          commit('SET_LAST_PAGE', res.data.meta.last_page)
          commit('SET_CURRENT_PAGE', res.data.meta.current_page)
        })
        .finally(() => {
          commit('SET_LOADING_ADDRESS', false)
        })
    },
    changeDefault({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.put(`v1/admin/addresses/${id}/set-default-address`)
        .then(res => {
          commit('SET_DEFAULT_ADDRESS', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    changeRegistryAddress({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.put(`v1/admin/addresses/${id}/set-registry-address`)
        .then(res => {
          commit('SET_REGISTRY_ADDRESS', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    getDistricts({ commit }, input) {
      commit('SET_LOADING_DISTRICT', true)

      if (input.length !== 0) {
        return this._vm.$http.get(`/v1/districts?keyword=${input}`)
          .then(res => {
            commit('SET_DISTRICTS_LIST', res.data.data)
          })
          .finally(() => {
            commit('SET_LOADING_DISTRICT', false)
          })
      }
      return commit('SET_LOADING_DISTRICT', false)
    },
    updateAddress({ commit }, { id, address }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.patch(`/v1/admin/addresses/${id}`, address)
        .then(res => {
          commit('SET_UPDATE_ADDRESS', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    deleteAddress({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete(`/v1/admin/addresses/${id}`)
        .then(res => {
          commit('SET_DELETE_ADDRESS', res.data.data.id)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
