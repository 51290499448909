export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'image_url',
    type: 'image',
    label: 'Ikon',
  },
  {
    key: 'name',
    label: 'Nama',
  },
  {
    key: 'slug',
    visibility: ['view'],
  },
  {
    key: 'parent_categories_count',
    label: 'Kategori Induk',
    suffix: ' Kategori',
  },
  {
    key: 'keyword',
    visibility: ['view'],
  },
]
