export const TransactionStatus2 = {
}

export const TransactionStatus = {
  pending: 'secondary',
  process: 'warning',
  picked_up: 'dark',
  arrived: 'success',
  completed: 'success',
  cancelled: 'danger',
  rejected: 'danger',
  expired: 'secondary',
}
