import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBTooltip } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import GAuth from 'vue-google-oauth2'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/lodash'

// Filter
import '@/filter/format'

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'consent',
  plugin_name: 'MiniapolyHomeAndBaby',
}
Vue.use(GAuth, gauthOption)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.directive('b-tooltip', VBTooltip)

// Composition API
Vue.use(VueCompositionAPI)

// TODO: find better way
// Global Helper
Vue.mixin({
  computed: {
    userDataMe() {
      return this.$store.state.auth.userData
    },
  },
  methods: {
    canAccess(action, resource) {
      if (this.userDataMe) {
        return this.$ability.can(action, resource)
      }

      return false
    },
  },
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.config.devtools = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
