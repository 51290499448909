// eslint-disable-next-line import/named
import { modeTypeMap } from '@/map/collection'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'image_url',
    type: 'image',
    label: 'Gambar',
  },
  {
    key: 'title',
    label: 'Judul',
  },
  {
    key: 'slug',
    label: 'Slug',
    visibility: ['view'],
  },
  {
    key: 'subtitle',
    label: 'Subjudul',
    visibility: ['view'],
  },
  {
    key: 'order',
    label: 'Urutan',
    visibility: ['view'],
  },
  {
    key: 'mode',
    label: 'Mode',
    type: 'dot',
    map: modeTypeMap,
    filter: {
      key: 'mode',
      type: 'select',
      label: 'Mode',
      options: [
        {
          value: 'auto',
          text: 'Auto',
        },
        {
          value: 'custom',
          text: 'Custom',
        },
      ],
    },
    visibility: ['view'],
  },
  {
    key: 'type',
    label: 'Tipe',
    type: 'dot',
    filter: {
      key: 'type',
      type: 'select',
      label: 'Tipe',
      options: [
        {
          value: 'normal',
          text: 'Normal',
        },
        {
          value: 'flash_sale',
          text: 'Flash Sale',
        },
        {
          value: 'low_stock',
          text: 'Low Stock',
        },
      ],
    },
    visibility: ['view'],
  },
  {
    key: 'items_count',
    label: 'Jumlah Produk',
    suffix: ' Produk',
  },
  {
    key: 'location',
    label: 'Lokasi',
    type: 'dot',
  },
  {
    key: 'is_active',
    label: 'Aktif',
    filter: {
      key: 'is_active',
      type: 'select',
      label: 'Aktif',
      options: [
        {
          value: true,
          text: 'Aktif',
        },
        {
          value: false,
          text: 'Tidak aktif',
        },
      ],
    },
  },
  {
    key: 'flash_sale_ended_at',
    label: 'Flash Sale Hingga',
    type: 'datetime',
    visibility: ['view'],
  },
  {
    key: 'filter',
    label: 'Filter',
    type: 'meta',
    visibility: ['view'],
  },
  {
    key: 'sort',
    label: 'Sort',
    visibility: ['view'],
  },
  {
    key: 'created_at',
    type: 'datetime',
    label: 'Dibuat pada',
    sortable: true,
    visibility: ['view'],
  },
]
