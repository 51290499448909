import { get, set } from '@/helper/localStorage'

export default {
  namespaced: true,
  state: {
    loadingCart: false,
    loadingSubmit: false,
    cartList: get('cartList') || [],
    countCart: get('countCart') || {},
    currentPage: 1,
    lastPage: 1,
  },
  getters: {
    cartList: state => {
      if (!state.cartList) {
        return undefined
      }

      return state.cartList
    },
    deleteCart: state => {
      if (!state.deleteCart) {
        return undefined
      }

      return state.deleteCart
    },
    countCart: state => {
      if (!state.countCart) {
        return undefined
      }

      return {
        ...state.countCart,
        // add or replace field here
      }
    },
  },
  mutations: {
    SET_CART_LIST(state, val) {
      // If it's not the first page, concatenate the new data to the existing data
      if (state.currentPage > 1) {
        state.cartList = state.cartList.concat(val)
      } else {
        // If it's the first page, replace the existing data with the new data
        state.cartList = val
      }
      set('cartList', state.cartList)
    },
    UPDATE_QTY_CART(state, val) {
      state.cartList = state.cartList.map(item => (item.id === val.id ? { ...item, quantity: val.quantity } : item))
    },
    SET_DELETE_CART: (state, id) => {
      state.cartList = state.cartList.filter(cart => cart.id !== id)
    },
    SET_COUNT_CART(state, val) {
      set('countCart', val)
      state.countCart = val
    },
    SET_LOADING_CART(state, val) {
      state.loadingCart = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
    SET_LAST_PAGE(state, val) {
      state.lastPage = val
    },
    SET_CURRENT_PAGE(state, val) {
      state.currentPage = val
    },
  },
  actions: {
    getListCart({ commit }, { id, page, limit }) {
      commit('SET_LOADING_CART', true)
      return this._vm.$http.get(`/v1/admin/users/${id}/carts?page=${page}&limit=${limit}`)
        .then(res => {
          commit('SET_CART_LIST', res.data.data)
          commit('SET_LAST_PAGE', res.data.meta.last_page)
          commit('SET_CURRENT_PAGE', res.data.meta.current_page)
        })
        .finally(() => {
          commit('SET_LOADING_CART', false)
        })
    },
    updateQtyCart({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http
        .patch(`/v1/admin/carts/${id}`, data)
        .then(res => {
          commit('UPDATE_QTY_CART', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    deleteCart({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete(`/v1/admin/carts/${id}`)
        .then(res => {
          commit('SET_DELETE_CART', res.data.data.id)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    getCountCart({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.get(`/v1/admin/carts-count/${id}`)
        .then(res => {
          commit('SET_COUNT_CART', res.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
