import historicalPaymentsReport from '@/store/dashboard/historical-payments'
import historicalRegistrationReport from '@/store/dashboard/historical-registration'
import topProductReport from './top-product'
import topBrandReport from './top-brand'
import topBuyerReport from './top-buyer'

export default {
  topProductReport,
  topBrandReport,
  topBuyerReport,
  historicalPaymentsReport,
  historicalRegistrationReport,
}
