export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    upload(_, file) {
      const data = new FormData()

      data.append('file', file)
      data.append('name', file.name)
      data.append('type', 'image')

      return this._vm.$http.post('/v1/upload/image', data)
    },
  },
}
