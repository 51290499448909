export default [
  {
    key: 'id',
    label: 'ID',
    type: 'id',
  },
  {
    key: 'name',
    label: 'Nama',
  },
  {
    key: 'phone',
    label: 'Nomor HP',
    type: 'phone',
  },
  {
    key: 'email',
    type: 'email',
  },
  {
    key: 'roles',
    label: 'Role',
    type: 'badges',
    map: {
      customer: 'primary',
      admin: 'danger',
    },
    filter: {
      key: 'role',
      type: 'select',
      options: [
        { value: 'customer', text: 'Customer' },
        { value: 'admin', text: 'Admin' },
      ],
    },
  },
  {
    key: 'is_profile_completed',
    label: 'Profil Lengkap',
    type: 'badges',
    map: {
      false: 'danger',
      true: 'success',
    },
    filter: {
      key: 'is_profile_completed',
      type: 'select',
      options: [
        { value: 'false', text: 'Belum Lengkap' },
        { value: 'true', text: 'Lengkap' },
      ],
    },
  },
  {
    key: 'is_email_verified',
    label: 'Email Terverifikasi',
    type: 'badges',
    map: {
      false: 'danger',
      true: 'success',
    },
    filter: {
      key: 'is_email_verified',
      type: 'select',
      options: [
        { value: 'false', text: 'Belum Terverifikasi' },
        { value: 'true', text: 'Terverifikasi' },
      ],
    },
  },
  {
    key: 'created_at',
    label: 'Dibuat Pada',
    type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Diubah Pada',
    type: 'date',
  },
]
