export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'category_id',
    label: 'Kategori',
    type: 'dot',
  },
  {
    key: 'question',
    label: 'Pertanyaan',
  },
  {
    key: 'answer',
    label: 'Jawaban',
    visibility: ['view'],
  },
  {
    key: 'created_at',
    type: 'datetime',
    label: 'Dibuat pada',
    sortable: true,
    visibility: ['view'],
  },
]
