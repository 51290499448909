import { get, set } from '@/helper/localStorage'

export default {
  namespaced: true,
  state: {
    loadingFavorite: false,
    allFavoriteList: get('allFavoriteList') || [],
    favoritePagination: [],
  },
  getters: {
    allFavoriteList: state => {
      if (!state.allFavoriteList) {
        return undefined
      }

      return state.allFavoriteList
    },
    favoriteParams: (state, getters, rootState) => ({
      page: state.favoritePagination || 1,
      limit: rootState.appConfig.settings.tablePerPage,
    }),
  },
  mutations: {
    SET_ALL_FAVORITE_LIST(state, val) {
      set('allFavoriteList', val)
      state.allFavoriteList = val
    },
    SET_FAVORITE_PAGINATION(state, val) {
      state.favoritePagination = val
    },
    SET_LOADING_FAVORITE(state, val) {
      state.loadingFavorite = val
    },
  },
  actions: {
    getListAllFavorite({ commit, getters }, { idUser, slugCollection }) {
      commit('SET_LOADING_FAVORITE', true)

      const { favoriteParams: params } = getters
      const page = params.page.current_page || 1
      const limit = params.page.per_page || 10
      return this._vm.$http.get(`/v1/admin/users/${idUser}/favorite-collections/${slugCollection}?page=${page}&limit=${limit}`)
        .then(res => {
          commit('SET_ALL_FAVORITE_LIST', res.data.data)
          commit('SET_FAVORITE_PAGINATION', res.data.data.favorites.meta)
        })
        .finally(() => {
          commit('SET_LOADING_FAVORITE', false)
        })
    },
  },
}
