import { get, set } from '@/helper/localStorage'

export default {
  namespaced: true,
  state: {
    itemsData: get('historicalPaymentItemsData', []),
    itemData: get('historicalPaymentItemData', []),
    lastUpdate: get('historicalPaymentLastUpdate', []),
    loadingItems: false,
    loadingItem: false,
  },
  getters: {
  },
  mutations: {
    SET_ITEMS(state, val) {
      set('itemsData', val)
      state.itemsData = val
    },
    SET_ITEM(state, val) {
      set('itemData', val)
      state.itemData = val
    },
    SET_LAST_UPDATE(state, val) {
      set('lastUpdate', val)
      state.lastUpdate = val
    },
    SET_LOADING_ITEMS(state, val) {
      state.loadingItems = val
    },
    SET_LOADING_ITEM(state, val) {
      state.loadingItem = val
    },
  },
  actions: {
    getItems({ commit }, { signal, startedAt, endedAt }) {
      commit('SET_LOADING_ITEMS', true)

      const params = { startedAt, endedAt }
      this._vm.$http.get('/v1/admin/report/historical-users-registration', { params, signal })
        .then(({ data }) => {
          commit('SET_ITEMS', data.data.slice(0, 7))
          commit('SET_LAST_UPDATE', data.last_updated_at)
        })
        .finally(() => {
          commit('SET_LOADING_ITEMS', false)
        })
    },
    getItem({ commit }) {
      const today = new Date().toISOString().split('T')[0]
      console.log('tanggal sekarag', today)

      commit('SET_LOADING_ITEM', true)
      this._vm.$http.get(`/v1/admin/report/historical-users-registration?start_date=${today}&end_date=${today}`)
        .then(({ data }) => {
          commit('SET_ITEM', data.data.slice(0, 7))
        })
        .finally(() => {
          commit('SET_LOADING_ITEM', false)
        })
    },
  },
}
