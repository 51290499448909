import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'

import resources from '@/router/resources'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        resource: 'Dashboard',
        action: 'read',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },

    {
      path: '/scrape-product',
      name: 'add-product',
      component: () => import('@/views/ScrapeProduct.vue'),
      meta: {
        resource: 'Product',
        action: 'manage',
      },
    },

    {
      path: '/add-product',
      name: 'add-product',
      component: () => import('@/views/AddProduct.vue'),
      meta: {
        resource: 'User',
        action: 'read',
      },
    },
    {
      path: '/sale/:id/products',
      name: 'sale-product',
      component: () => import('@/views/EventProduct.vue'),
      meta: {
        pageTitle: 'Ubah Sale',
        resource: 'Sale',
        action: 'manage',
        breadcrumb: [
          {
            text: 'Sale',
            to: '/sale',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },

    // All resource routes
    ...resources,

    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect to login if not logged in
  if (!canNavigate(to) && !isLoggedIn) {
    return next({ name: 'login' })
  }

  // Redirect to home if logged in
  if (!canNavigate(to) && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData.roles))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
