export default [
  {
    key: 'id',
    type: 'id',
  },
  {
    key: 'title',
    label: 'Judul',
  },
  {
    key: 'description',
    label: 'Deskripsi',
    visibility: ['view'],
  },
  {
    key: 'is_active',
    label: 'Aktif',
    filter: {
      key: 'is_active',
      type: 'select',
      label: 'Aktif',
      options: [
        {
          value: true,
          text: 'Aktif',
        },
        {
          value: false,
          text: 'Tidak aktif',
        },
      ],
    },
  },
  {
    key: 'started_at',
    type: 'datetime',
  },
  {
    key: 'ended_at',
    type: 'datetime',
  },
]
