import Vue from 'vue'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import findIndex from 'lodash/findIndex'
import intersectionBy from 'lodash/intersectionBy'
import differenceWith from 'lodash/differenceWith'
import find from 'lodash/find'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'
import get from 'lodash/get'
import set from 'lodash/set'
import isMatch from 'lodash/isMatch'
import merge from 'lodash/merge'
import kebabCase from 'lodash/kebabCase'
import startCase from 'lodash/startCase'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'

const plugin = {
  install() {
    Vue.prototype._ = {
      isEqual,
      some,
      findIndex,
      intersectionBy,
      differenceWith,
      find,
      filter,
      isEmpty,
      set,
      map,
      uniqBy,
      get,
      isMatch,
      merge,
      kebabCase,
      startCase,
      cloneDeep,
      sortBy,
    }
  },
}

Vue.use(plugin)
