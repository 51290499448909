export const get = (key, fallback = undefined) => {
  const stringData = localStorage.getItem(key)
  let data = fallback

  if (stringData) {
    data = JSON.parse(stringData)
  }

  return data
}

export const set = (key, val) => {
  if (val) {
    localStorage.setItem(key, JSON.stringify(val))
  } else {
    localStorage.removeItem(key)
  }
}
