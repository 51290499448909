export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'images[0].image_url',
    type: 'image',
    label: 'Foto',
  },
  {
    key: 'name',
    label: 'Nama produk',
    sortable: true,
  },
  {
    key: 'is_active',
    label: 'Aktif',
    filter: {
      key: 'is_active',
      type: 'select',
      label: 'Status',
      options: [
        { value: true, text: 'Aktif' },
        { value: false, text: 'Tidak aktif' },
      ],
    },
  },
  {
    key: 'category.name',
    type: 'url',
    label: 'Kategori',
    url: {
      callback: data => `/category/${data.category_id}`,
    },
  },
  {
    key: 'created_at',
    type: 'datetime',
    label: 'Dibuat pada',
    sortable: true,
    visibility: ['view'],
  },
]
