import { TransactionStatus } from '@/map/status'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'created_at',
    type: 'datetime',
    label: 'Transaksi pada',
    sortable: true,
    filter: {
      startKey: 'start_date',
      endKey: 'end_date',
      type: 'datetime',
    },
  },
  {
    key: 'paid_at',
    type: 'datetime',
    label: 'Dibayar pada',
    visibility: ['view'],
  },
  {
    key: 'invoice_number',
    label: 'No Invoice',
  },
  {
    key: 'status',
    type: 'badge',
    text: 'status_text',
    map: TransactionStatus,
  },
  {
    key: 'product_total',
    label: 'Total Produk',
    prefix: 'Rp ',
    visibility: ['view'],
  },
  {
    key: 'delivery_total',
    label: 'Biaya Pengiriman',
    prefix: 'Rp ',
    visibility: ['view'],
  },
  {
    key: 'transaction_total',
    label: 'Total Belanja',
    prefix: 'Rp ',
  },
  {
    key: 'user.name',
    label: 'Pembeli',
    type: 'profile',
    image: 'user.image_url',
    url: {
      callback: data => `/user/${data.user.id}`,
    },
  },
  {
    key: 'transaction_item.product.name',
    image: 'transaction_item.image_url',
    label: 'Produk',
    type: 'profile',
    visibility: ['view'],
    url: {
      callback: data => `/product/${data.transaction_item.product.id}`,
    },
  },
]
