export default [
  {
    subject: 'Dashboard',
    action: 'read',
  },
  {
    subject: 'User',
    action: 'manage',
  },
  {
    subject: 'Banner',
    action: 'manage',
  },
  {
    subject: 'Brand',
    action: 'manage',
  },
  {
    subject: 'Category',
    action: 'manage',
  },
  {
    subject: 'Transaction',
    action: 'manage',
  },
  {
    subject: 'Settings',
    action: 'manage',
  },
  {
    subject: 'Voucher',
    action: 'manage',
  },
  {
    subject: 'Product',
    action: 'manage',
  },
  {
    subject: 'RootCategory',
    action: 'manage',
  },
  {
    subject: 'ParentCategory',
    action: 'manage',
  },
  {
    subject: 'PaymentMethod',
    action: 'manage',
  },
  {
    subject: 'Collection',
    action: 'manage',
  },
  {
    subject: 'QuestionCategory',
    action: 'manage',
  },
  {
    subject: 'Question',
    action: 'manage',
  },
  {
    subject: 'Sale',
    action: 'manage',
  },
]
