export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'title',
    label: 'Judul',
  },
  {
    key: 'is_active',
    label: 'Aktif',
    filter: {
      key: 'is_active',
      type: 'select',
      label: 'Status',
      options: [
        {
          value: true,
          text: 'Aktif',
        },
        {
          value: false,
          text: 'Tidak aktif',
        },
      ],
    },
  },
  {
    key: 'is_claimable',
    label: 'Dapat Diklaim',
    visibility: ['view'],
    filter: {
      key: 'is_claimable',
      type: 'select',
      label: 'Dapat Diklaim',
      options: [
        {
          value: true,
          text: 'Aktif',
        },
        {
          value: false,
          text: 'Tidak aktif',
        },
      ],
    },
  },
  {
    key: 'is_private',
    label: 'Privat',
    visibility: ['view'],
    filter: {
      key: 'is_private',
      type: 'select',
      label: 'Privat',
      options: [
        {
          value: true,
          text: 'Aktif',
        },
        {
          value: false,
          text: 'Tidak aktif',
        },
      ],
    },
  },
  {
    key: 'percentage',
    label: 'Percentage',
    suffix: '%',
  },
  {
    key: 'maximum',
    label: 'Potongan maks.',
    prefix: 'Rp ',
  },
  {
    key: 'max_transaction',
    label: 'Kuota per pengguna',
    visibility: ['view'],
  },
  {
    key: 'quota',
    label: 'Kuota keseluruhan',
    visibility: ['view'],
  },
  {
    key: 'min_product_total',
    label: 'Min. transaksi',
    prefix: 'Rp ',
    visibility: ['view'],
  },
  {
    key: 'redeemed_count',
    label: 'Digunakan',
    suffix: ' Pengguna',
  },
  {
    key: 'type',
    type: 'dot',
    text: 'type_text',
    map: {
      allow_all: 'primary',
      whitelist: 'success',
      blacklist: 'danger',
    },
    filter: {
      key: 'type',
      type: 'select',
      label: 'Tipe',
      options: [
        {
          value: 'allow_all',
          text: 'Semua Brand',
        },
        {
          value: 'whitelist',
          text: 'Whitelist',
        },
        {
          value: 'blacklist',
          text: 'Blacklist',
        },
      ],
    },
  },
  {
    key: 'brand.name',
    label: 'Brand',
    type: 'profile',
    image: 'brand.image_url',
    url: {
      callback: data => `/brand/${data.brand.id}`,
    },
  },
  {
    key: 'brand_count',
    label: 'Jumlah Brand',
    visibility: ['view'],
  },
  {
    key: 'terms',
    label: 'Syarat dan Ketentuan',
    visibility: ['view'],
  },
]
