export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'image_url',
    type: 'image',
    label: 'Ikon',
  },
  {
    key: 'name',
    label: 'Nama',
  },
  {
    key: 'slug',
  },
  {
    key: 'parent_category.name',
    label: 'Kategori Induk',
    type: 'url',
    url: {
      callback: data => (data.parent_category ? `/parent-category/${data.parent_category.id}` : null),
    },
  },
  {
    key: 'keyword',
    label: 'Keyword',
    visibility: ['view'],
  },
]
