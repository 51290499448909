// eslint-disable-next-line import/prefer-default-export
export const paymentGatewayMap = {
  manual: 'secondary',
  midtrans: 'primary',
  xendit: 'danger',
}

export const paymentCategoryMap = {
  VA: 'dark',
  EW: 'dark',
  CC: 'dark',
  MN: 'dark',
  BT: 'dark',
}
