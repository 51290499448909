export default {
  namespaced: true,
  state: {
    userData: JSON.parse(localStorage.getItem('userData')),
  },
  getters: {
  },
  mutations: {
    UPDATE_USER_DATA(state, val) {
      localStorage.setItem('userData', JSON.stringify(val))
      state.userData = val
    },
  },
  actions: {},
}
