import { settingTypeMap } from '@/map/setting'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'title',
    label: 'Judul',
  },
  {
    key: 'key',
    label: 'Keyword',
  },
  {
    key: 'description',
    label: 'Deskripsi',
    visibility: ['view'],
  },
  {
    key: 'type',
    label: 'Tipe',
    type: 'badge',
    map: settingTypeMap,
    filter: {
      key: 'type',
      type: 'select',
      options: [
        { value: 'switch', text: 'Switch' },
        { value: 'number', text: 'Number' },
        { value: 'text', text: 'Text' },
        { value: 'description', text: 'Description' },
      ],
    },
  },
  {
    key: 'value',
    label: 'Nilai',
  },
  {
    key: 'created_at',
    type: 'datetime',
    label: 'Dibuat pada',
    sortable: true,
    visibility: ['view'],
  },
]
