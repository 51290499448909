import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/table/sale'
import axios from 'axios'

const oldApi = axios.create({
  baseURL: process.env.VUE_APP_OLD_API_URL,
  timeout: 30000,
  headers: {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3BvcnRhbC5taW5pYXBvbHkuY29tL2FwaS92MS9sb2dpbi1hZG1pbiIsImlhdCI6MTcxOTk3ODgxOCwiZXhwIjoxNzIyNTcwODE4LCJuYmYiOjE3MTk5Nzg4MTgsImp0aSI6Im40cXZnZlFPOXZNMnRsa04iLCJzdWIiOiIzMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.CEYTnSk54bKohU38danYhrlyvjfrNP1o50cYwCXxQJg',
  },
})

export default {
  namespaced: true,
  state: {
    tableFields: get('saleTableFields', fields),
    tableData: [],
    tablePagination: get('saleTablePagination'),
    tableKeyword: get('saleTableKeyword'),
    tableSort: get('saleTableSort'),
    tableFilter: get('saleTableFilter', []),
    detailData: get('saleDetailData'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      const symbol = state.tableSort.direction === 'desc' ? '-' : ''

      return `${symbol}${state.tableSort.key}`
    },
    tableData: state => {
      if (!state.tableData) {
        return []
      }

      return state.tableData.map(item => ({
        ...item,
        // add or replace field here
      }))
    },
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
        // add or replace field here
      }
    },
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [`filter[${elem.key}]`]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      'filter[keyword]': state.tableKeyword,
      ...getters.tableFilter,
      sort: getters.tableSort,
      page: _get(state, 'tablePagination.current_page', 1),
      limit: rootState.appConfig.settings.tablePerPage,
    }),
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('saleTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('saleTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('saleTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('saleTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('saleDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('saleTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('saleTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      oldApi.get('/v1/admin/sales', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data)
          commit('SET_TABLE_PAGINATION', data.meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    search(_, payload) {
      return oldApi.get('/v1/admin/sales', {
        params: {
          'filter[ids]': payload ? payload.ids : undefined,
          'filter[keyword]': payload ? payload.keyword : undefined,
        },
      })
        .then(({ data }) => data.data)
        .catch(() => [])
    },
    getProductList(_, id) {
      return oldApi.get(`/v1/admin/sales/${id}/products`)
        .then(({ data }) => data.data)
        .catch(() => [])
    },
    getSaleSkus(_, id) {
      return oldApi.get(`/v1/admin/sales/${id}/sale-skus`)
        .then(({ data }) => data.data)
        .catch(() => [])
    },
    createSaleSkus(_, { id, skus }) {
      return oldApi.post(`/v1/admin/sales/${id}/sale-skus`, { skus })
        .then(({ data }) => data.data)
    },
    changeSaleSkusPrice(_, { id, skus }) {
      return oldApi.put(`/v1/admin/sales/${id}/sale-skus/price`, { skus })
        .then(({ data }) => data.data)
    },
    deleteSaleSku(_, { saleId, skuId }) {
      return oldApi.delete(`/v1/admin/sales/${saleId}/skus/${skuId}`)
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      oldApi.get(`/v1/admin/sales/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    create({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return oldApi.post('/v1/admin/sales', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    uploadSkus({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return oldApi.post(`/v1/admin/sales/${id}/sale-skus`, data)
        .then(res => res.data.data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    update({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return oldApi.patch(`/v1/admin/sales/${id}`, data)
        .then(res => {
          commit('SET_DETAIL_DATA', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    delete({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return oldApi.delete(`/v1/admin/sales/${id}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
