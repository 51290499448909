export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'image_url',
    type: 'image',
    label: 'Ikon',
  },
  {
    key: 'name',
    label: 'Nama',
  },
  {
    key: 'slug',
    visibility: ['view'],
  },
  {
    key: 'root_category.name',
    label: 'Kategori Utama',
    type: 'url',
    url: {
      callback: data => (data.root_category ? `/root-category/${data.root_category.id}` : null),
    },
  },
  {
    key: 'categories_count',
    label: 'Kategori',
    suffix: ' Kategori',
  },
  {
    key: 'keyword',
    visibility: ['view'],
  },
]
