export default [
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/User.vue'),
    meta: {
      pageTitle: 'Pengguna',
      resource: 'User',
      action: 'read',
      breadcrumb: [
        {
          text: 'Pengguna',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/create',
    name: 'user-create',
    component: () => import('@/views/UserCreate.vue'),
    meta: {
      pageTitle: 'Buat Pengguna',
      resource: 'User',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Pengguna',
          to: '/user',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/:id',
    name: 'user-detail',
    component: () => import('@/views/UserDetail.vue'),
    meta: {
      pageTitle: 'Detail Pengguna',
      resource: 'User',
      action: 'read',
      breadcrumb: [
        {
          text: 'Pengguna',
          to: '/user',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/:id/edit',
    name: 'user-edit',
    component: () => import('@/views/UserEdit.vue'),
    meta: {
      pageTitle: 'Ubah Pengguna',
      resource: 'User',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Pengguna',
          to: '/user',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/:id_user/favorite/:id_collection',
    name: 'user-favorite',
    component: () => import('@/views/FavoriteDetail.vue'),
    meta: {
      pageTitle: 'Detail Favorit',
      resource: 'User',
      action: 'read',
      breadcrumb: [
        {
          text: 'Favorit',
          to: '/user',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banner',
    name: 'banner',
    component: () => import('@/views/Banner.vue'),
    meta: {
      pageTitle: 'Banner',
      resource: 'Banner',
      action: 'read',
      breadcrumb: [
        {
          text: 'Banner',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banner/create',
    name: 'banner-create',
    component: () => import('@/views/BannerCreate.vue'),
    meta: {
      pageTitle: 'Buat Banner',
      resource: 'Banner',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Banner',
          to: '/banner',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banner/:id',
    name: 'banner-detail',
    component: () => import('@/views/BannerDetail.vue'),
    meta: {
      pageTitle: 'Detail Banner',
      resource: 'Banner',
      action: 'read',
      breadcrumb: [
        {
          text: 'Banner',
          to: '/banner',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/banner/:id/edit',
    name: 'banner-edit',
    component: () => import('@/views/BannerEdit.vue'),
    meta: {
      pageTitle: 'Ubah Banner',
      resource: 'Banner',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Banner',
          to: '/banner',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('@/views/Category.vue'),
    meta: {
      pageTitle: 'Kategori',
      resource: 'Category',
      action: 'read',
      breadcrumb: [
        {
          text: 'Kategori',
          active: true,
        },
      ],
    },
  },
  {
    path: '/category/create',
    name: 'category-create',
    component: () => import('@/views/CategoryCreate.vue'),
    meta: {
      pageTitle: 'Buat Kategori',
      resource: 'Category',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Kategori',
          to: '/category',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/category/:id',
    name: 'category-detail',
    component: () => import('@/views/CategoryDetail.vue'),
    meta: {
      pageTitle: 'Detail Kategori',
      resource: 'Category',
      action: 'read',
      breadcrumb: [
        {
          text: 'Kategori',
          to: '/category',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/category/:id/edit',
    name: 'category-edit',
    component: () => import('@/views/CategoryEdit.vue'),
    meta: {
      pageTitle: 'Ubah Kategori',
      resource: 'Category',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Kategori',
          to: '/category',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: () => import('@/views/Transaction.vue'),
    meta: {
      pageTitle: 'Transaksi',
      resource: 'Transaction',
      action: 'read',
      breadcrumb: [
        {
          text: 'Transaksi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/transaction/:id',
    name: 'transaction-detail',
    component: () => import('@/views/TransactionDetail.vue'),
    meta: {
      pageTitle: 'Detail Transaksi',
      resource: 'Transaction',
      action: 'read',
      breadcrumb: [
        {
          text: 'Transaksi',
          to: '/transaction',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/Settings.vue'),
    meta: {
      pageTitle: 'Pengaturan',
      resource: 'Settings',
      action: 'read',
      breadcrumb: [
        {
          text: 'Pengaturan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/create',
    name: 'settings-create',
    component: () => import('@/views/SettingsCreate.vue'),
    meta: {
      pageTitle: 'Buat Pengaturan',
      resource: 'Settings',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Pengaturan',
          to: '/settings',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/:id',
    name: 'settings-detail',
    component: () => import('@/views/SettingsDetail.vue'),
    meta: {
      pageTitle: 'Detail Pengaturan',
      resource: 'Settings',
      action: 'read',
      breadcrumb: [
        {
          text: 'Pengaturan',
          to: '/settings',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/:id/edit',
    name: 'settings-edit',
    component: () => import('@/views/SettingsEdit.vue'),
    meta: {
      pageTitle: 'Ubah Pengaturan',
      resource: 'Settings',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Pengaturan',
          to: '/settings',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/voucher',
    name: 'voucher',
    component: () => import('@/views/Voucher.vue'),
    meta: {
      pageTitle: 'Voucher',
      resource: 'Voucher',
      action: 'read',
      breadcrumb: [
        {
          text: 'Voucher',
          active: true,
        },
      ],
    },
  },
  {
    path: '/voucher/create',
    name: 'voucher-create',
    component: () => import('@/views/VoucherCreate.vue'),
    meta: {
      pageTitle: 'Buat Voucher',
      resource: 'Voucher',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Voucher',
          to: '/voucher',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/voucher/:id',
    name: 'voucher-detail',
    component: () => import('@/views/VoucherDetail.vue'),
    meta: {
      pageTitle: 'Detail Voucher',
      resource: 'Voucher',
      action: 'read',
      breadcrumb: [
        {
          text: 'Voucher',
          to: '/voucher',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/voucher/:id/edit',
    name: 'voucher-edit',
    component: () => import('@/views/VoucherEdit.vue'),
    meta: {
      pageTitle: 'Ubah Voucher',
      resource: 'Voucher',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Voucher',
          to: '/voucher',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('@/views/Brand.vue'),
    meta: {
      pageTitle: 'Brand',
      resource: 'Brand',
      action: 'read',
      breadcrumb: [
        {
          text: 'Brand',
          active: true,
        },
      ],
    },
  },
  {
    path: '/brand/create',
    name: 'brand-create',
    component: () => import('@/views/BrandCreate.vue'),
    meta: {
      pageTitle: 'Buat Brand',
      resource: 'Brand',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Brand',
          to: '/brand',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/brand/:id',
    name: 'brand-detail',
    component: () => import('@/views/BrandDetail.vue'),
    meta: {
      pageTitle: 'Detail Brand',
      resource: 'Brand',
      action: 'read',
      breadcrumb: [
        {
          text: 'Brand',
          to: '/brand',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/brand/:id/edit',
    name: 'brand-edit',
    component: () => import('@/views/BrandEdit.vue'),
    meta: {
      pageTitle: 'Ubah Brand',
      resource: 'Brand',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Brand',
          to: '/brand',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/Product.vue'),
    meta: {
      pageTitle: 'Produk',
      resource: 'Product',
      action: 'read',
      breadcrumb: [
        {
          text: 'Produk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product/create',
    name: 'product-create',
    component: () => import('@/views/ProductCreate.vue'),
    meta: {
      pageTitle: 'Buat Produk',
      resource: 'Product',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Produk',
          to: '/product',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product/:id',
    name: 'product-detail',
    component: () => import('@/views/ProductDetail.vue'),
    meta: {
      pageTitle: 'Detail Produk',
      resource: 'Product',
      action: 'read',
      breadcrumb: [
        {
          text: 'Produk',
          to: '/product',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/product/:id/edit',
    name: 'product-edit',
    component: () => import('@/views/ProductEdit.vue'),
    meta: {
      pageTitle: 'Ubah Produk',
      resource: 'Product',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Produk',
          to: '/product',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payment-method',
    name: 'payment-method',
    component: () => import('@/views/PaymentMethod.vue'),
    meta: {
      pageTitle: 'Metode Pembayaran',
      resource: 'PaymentMethod',
      action: 'read',
      breadcrumb: [
        {
          text: 'Metode Pembayaran',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payment-method/create',
    name: 'payment-method-create',
    component: () => import('@/views/PaymentMethodCreate.vue'),
    meta: {
      pageTitle: 'Buat Metode Pembayaran',
      resource: 'PaymentMethod',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Metode Pembayaran',
          to: '/payment-method',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payment-method/:id',
    name: 'payment-method-detail',
    component: () => import('@/views/PaymentMethodDetail.vue'),
    meta: {
      pageTitle: 'Detail Metode Pembayaran',
      resource: 'PaymentMethod',
      action: 'read',
      breadcrumb: [
        {
          text: 'Metode Pembayaran',
          to: '/payment-method',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/payment-method/:id/edit',
    name: 'payment-method-edit',
    component: () => import('@/views/PaymentMethodEdit.vue'),
    meta: {
      pageTitle: 'Ubah Metode Pembayaran',
      resource: 'PaymentMethod',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Metode Pembayaran',
          to: '/payment-method',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/root-category',
    name: 'root-category',
    component: () => import('@/views/RootCategory.vue'),
    meta: {
      pageTitle: 'Kategori Utama',
      resource: 'RootCategory',
      action: 'read',
      breadcrumb: [
        {
          text: 'Kategori Utama',
          active: true,
        },
      ],
    },
  },
  {
    path: '/root-category/create',
    name: 'root-category-create',
    component: () => import('@/views/RootCategoryCreate.vue'),
    meta: {
      pageTitle: 'Buat Kategori Utama',
      resource: 'RootCategory',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Kategori Utama',
          to: '/root-category',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/root-category/:id',
    name: 'root-category-detail',
    component: () => import('@/views/RootCategoryDetail.vue'),
    meta: {
      pageTitle: 'Detail Kategori Utama',
      resource: 'RootCategory',
      action: 'read',
      breadcrumb: [
        {
          text: 'Kategori Utama',
          to: '/root-category',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/root-category/:id/edit',
    name: 'root-category-edit',
    component: () => import('@/views/RootCategoryEdit.vue'),
    meta: {
      pageTitle: 'Ubah Kategori Utama',
      resource: 'RootCategory',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Kategori Utama',
          to: '/root-category',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parent-category',
    name: 'parent-category',
    component: () => import('@/views/ParentCategory.vue'),
    meta: {
      pageTitle: 'Kategori Induk',
      resource: 'ParentCategory',
      action: 'read',
      breadcrumb: [
        {
          text: 'Kategori Induk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parent-category/create',
    name: 'parent-category-create',
    component: () => import('@/views/ParentCategoryCreate.vue'),
    meta: {
      pageTitle: 'Buat Kategori Induk',
      resource: 'ParentCategory',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Kategori Induk',
          to: '/parent-category',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parent-category/:id',
    name: 'parent-category-detail',
    component: () => import('@/views/ParentCategoryDetail.vue'),
    meta: {
      pageTitle: 'Detail Kategori Induk',
      resource: 'ParentCategory',
      action: 'read',
      breadcrumb: [
        {
          text: 'Kategori Induk',
          to: '/parent-category',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/parent-category/:id/edit',
    name: 'parent-category-edit',
    component: () => import('@/views/ParentCategoryEdit.vue'),
    meta: {
      pageTitle: 'Ubah Kategori Induk',
      resource: 'ParentCategory',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Kategori Induk',
          to: '/parent-category',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/collection',
    name: 'collection',
    component: () => import('@/views/Collection.vue'),
    meta: {
      pageTitle: 'Koleksi',
      resource: 'Collection',
      action: 'read',
      breadcrumb: [
        {
          text: 'Koleksi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/collection/create',
    name: 'collection-create',
    component: () => import('@/views/CollectionCreate.vue'),
    meta: {
      pageTitle: 'Buat Koleksi',
      resource: 'Collection',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Koleksi',
          to: '/collection',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/collection/:id',
    name: 'collection-detail',
    component: () => import('@/views/CollectionDetail.vue'),
    meta: {
      pageTitle: 'Detail Koleksi',
      resource: 'Collection',
      action: 'read',
      breadcrumb: [
        {
          text: 'Koleksi',
          to: '/banner',
        },
        {
          text: 'Detail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/collection/:id/edit',
    name: 'collection-edit',
    component: () => import('@/views/CollectionEdit.vue'),
    meta: {
      pageTitle: 'Ubah Koleksi',
      resource: 'Collection',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Koleksi',
          to: '/collection',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/question-category',
    name: 'question-category',
    component: () => import('@/views/QuestionCategory.vue'),
    meta: {
      pageTitle: 'Koleksi Pertanyaan',
      resource: 'QuestionCategory',
      action: 'read',
      breadcrumb: [
        {
          text: 'Koleksi Pertanyaan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/question-category/create',
    name: 'question-category-create',
    component: () => import('@/views/QuestionCategoryCreate.vue'),
    meta: {
      pageTitle: 'Buat Koleksi Pertanyaan',
      resource: 'QuestionCategory',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Koleksi Pertanyaan',
          to: '/question-category',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/question-category/:id',
    name: 'question-category-detail',
    component: () => import('@/views/QuestionCategoryDetail.vue'),
    meta: {
      pageTitle: 'Detail Koleksi Pertanyaan',
      resource: 'QuestionCategory',
      action: 'read',
      breadcrumb: [
        {
          text: 'Koleksi Pertanyaan',
          to: '/question-category',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/question-category/:id/edit',
    name: 'question-category-edit',
    component: () => import('@/views/QuestionCategoryEdit.vue'),
    meta: {
      pageTitle: 'Ubah Koleksi Pertanyaan',
      resource: 'Collection',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Koleksi Pertanyaan',
          to: '/collection',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/views/Question.vue'),
    meta: {
      pageTitle: 'Question',
      resource: 'Question',
      action: 'read',
      breadcrumb: [
        {
          text: 'Question',
          active: true,
        },
      ],
    },
  },
  {
    path: '/question/create',
    name: 'question-create',
    component: () => import('@/views/QuestionCreate.vue'),
    meta: {
      pageTitle: 'Buat Pertanyaan',
      resource: 'Question',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Pertanyaan',
          to: '/question',
        },
        {
          text: 'Buat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/question/:id',
    name: 'question-detail',
    component: () => import('@/views/QuestionDetail.vue'),
    meta: {
      pageTitle: 'Detail Pertanyaan',
      resource: 'Question',
      action: 'read',
      breadcrumb: [
        {
          text: 'Pertanyaan',
          to: '/question',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/question/:id/edit',
    name: 'question-edit',
    component: () => import('@/views/QuestionEdit.vue'),
    meta: {
      pageTitle: 'Ubah Pertanyaan',
      resource: 'Question',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Pertanyaan',
          to: '/question',
        },
        {
          text: 'Ubah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sale',
    name: 'sale',
    component: () => import('@/views/Sale.vue'),
    meta: {
      pageTitle: 'Sale',
      resource: 'Sale',
      action: 'read',
      breadcrumb: [
        {
          text: 'Sale',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sale/create',
    name: 'sale-create',
    component: () => import('@/views/SaleCreate.vue'),
    meta: {
      pageTitle: 'Tambah Sale',
      resource: 'Sale',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Sale',
          to: '/sale',
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sale/:id',
    name: 'sale-detail',
    component: () => import('@/views/SaleDetail.vue'),
    meta: {
      pageTitle: 'Detail Sale',
      resource: 'Sale',
      action: 'read',
      breadcrumb: [
        {
          text: 'Sale',
          to: '/sale',
        },
        {
          text: 'View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sale/:id/edit',
    name: 'sale-edit',
    component: () => import('@/views/SaleEdit.vue'),
    meta: {
      pageTitle: 'Ubah Sale',
      resource: 'Sale',
      action: 'manage',
      breadcrumb: [
        {
          text: 'Sale',
          to: '/sale',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
]
