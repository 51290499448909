import { get, set } from '@/helper/localStorage'

export default {
  namespaced: true,
  state: {
    loadingCollection: false,
    collectionList: get('collectionList') || [],
    lastPage: 1,
    currentPage: 1,
  },
  getters: {
    collectionList: state => {
      if (!state.collectionList) {
        return undefined
      }

      return state.collectionList
    },
  },
  mutations: {
    SET_COLLECTION_LIST(state, val) {
      set('collectionList', val)
      state.collectionList = state.currentPage > 1 ? state.collectionList.concat(val.slice(1)) : val
    },
    SET_LOADING_COLLECTION(state, val) {
      state.loadingCollection = val
    },
    SET_LAST_PAGE(state, val) {
      state.lastPage = val
    },
    SET_CURRENT_PAGE(state, val) {
      state.currentPage = val
    },
  },
  actions: {
    getListCollection({ commit }, { id, page, limit }) {
      commit('SET_LOADING_COLLECTION', true)
      return this._vm.$http.get(`/v1/admin/users/${id}/favorite-collections?page=${page}&limit=${limit}`)
        .then(res => {
          commit('SET_COLLECTION_LIST', res.data.data)
          commit('SET_LAST_PAGE', res.data.meta.last_page)
          commit('SET_CURRENT_PAGE', res.data.meta.current_page)
        })
        .finally(() => {
          commit('SET_LOADING_COLLECTION', false)
        })
    },
  },
}
