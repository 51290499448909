import { paymentCategoryMap, paymentGatewayMap } from '@/map/payment-method'

export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'image_url',
    type: 'image',
    label: 'Logo',
  },
  {
    key: 'name',
    label: 'Nama',
  },
  {
    key: 'fixed_fee',
    label: 'Biaya Tetap',
    type: 'currency',
    prefix: 'Rp ',
  },
  {
    key: 'percentage_fee',
    label: 'Biaya Persentase',
    suffix: '%',
  },
  {
    key: 'category',
    label: 'Kode Kategori',
    type: 'badge',
    map: paymentCategoryMap,
    filter: {
      key: 'category',
      type: 'select',
      options: [
        { value: 'VA', text: 'Virtual Account' },
        { value: 'EW', text: 'E-Wallet' },
        { value: 'CC', text: 'Credit Card' },
        { value: 'MN', text: 'Manual' },
        { value: 'BT', text: 'Bank Transfer' },
      ],
    },
  },
  {
    key: 'payment_gateway',
    label: 'Payment Gateway',
    type: 'badge',
    map: paymentGatewayMap,
    visibility: ['view'],
    filter: {
      key: 'payment_gateway',
      type: 'select',
      options: [
        { value: 'manual', text: 'Manual' },
        { value: 'midtrans', text: 'Midtrans' },
        { value: 'xendit', text: 'Xendit' },
      ],
    },
  },
  {
    key: 'is_active',
    label: 'Status',
    filter: {
      key: 'is_active',
      type: 'select',
      options: [
        { value: '1', text: 'Aktif' },
        { value: '0', text: 'Tidak Aktif' },
      ],
    },
  },
  {
    key: 'code',
    label: 'Kode',
    visibility: ['view'],
  },
  {
    key: 'snap_code',
    label: 'Kode Snap',
    visibility: ['view'],
  },
  {
    key: 'created_at',
    type: 'datetime',
    label: 'Dibuat pada',
    sortable: true,
    visibility: ['view'],
  },
]
