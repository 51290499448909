export default [
  {
    key: 'id',
    type: 'id',
  },
  {
    key: 'image_url',
    type: 'image',
    label: 'Ikon Kategori',
  },
  {
    key: 'name',
    label: 'Nama',
  },
  {
    key: 'slug',
    label: 'Slug',
  },
  {
    key: 'created_at',
    type: 'datetime',
    label: 'Dibuat pada',
    sortable: true,
  },
]
