import { get, set } from '@/helper/localStorage'

export default {
  namespaced: true,
  state: {
    itemsData: get('topBrandReportItemsData', []),
    loadingItems: false,
  },
  getters: {
  },
  mutations: {
    SET_ITEMS(state, val) {
      set('itemsData', val)
      state.itemsData = val
    },
    SET_LOADING_ITEMS(state, val) {
      state.loadingItems = val
    },
  },
  actions: {
    getItems({ commit }, { signal, startedAt, endedAt }) {
      commit('SET_LOADING_ITEMS', true)

      const params = { startedAt, endedAt }
      this._vm.$http.get('/v1/admin/report/top-brands', { params, signal })
        .then(({ data }) => {
          // console.log(data)
          commit('SET_ITEMS', data.data.slice(0, 10))
        })
        .finally(() => {
          commit('SET_LOADING_ITEMS', false)
        })
    },
  },
}
