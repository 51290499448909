import adminAbilities from '@/libs/acl/roles/admin'

export const initialAbility = [
  {
    subject: 'Auth',
    action: 'read',
  },
]

export const _ = undefined

export const adminAbility = adminAbilities
