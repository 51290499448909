import Vue from 'vue'
import get from 'lodash/get'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
// import { logout } from '@/auth/utils'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 300000, // 5 minutes
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.response.use(response => response,
  error => {
    const code = parseInt(error.response && error.response.status, 10)
    let alertTitle = ''
    let alertMessage = ''

    switch (code) {
      case 400:
        alertTitle = 'There is an error'
        alertMessage = error.response.data.message
        break
      case 401:
        alertTitle = 'Your session has ended'
        alertMessage = 'Please login to continue.'
        // logout()
        break
      case 404:
        alertTitle = 'Data could not be found'
        alertMessage = error.response.data.message
        break
      case 422:
        alertTitle = 'Please check Your input'
        alertMessage = error.response.data.message
        break
      default:
        alertTitle = 'Something went wrong'
        alertMessage = get('error.response.data.errors.message')
        // Do something with unhandled error
        return Promise.reject(error)
    }

    // if there is message
    if (alertMessage) {
      // could not access the main Vue instance from axios interceptors
      // need to find a better practice
      const vm = new Vue()
      vm.$toast({
        component: ToastificationContent,
        props: {
          title: alertTitle,
          icon: 'BellIcon',
          text: alertMessage,
          variant: 'danger',
        },
      })
    }

    return Promise.reject(error)
  })

export default axiosIns
