export const modeTypeMap = {
  auto: 'primary',
  custom: 'danger',
}

export const typeMap = {
  normal: 'primary',
  flash_sale: 'success',
  low_stock: 'warning',
}

export const locationTypeMap = {
  home: 'primary',
  cart: 'info',
}
