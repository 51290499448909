export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'image_url',
    type: 'image',
    label: 'Foto Brand',
  },
  {
    key: 'name',
    label: 'Nama',
  },
  {
    key: 'slug',
    label: 'Slug',
    visibility: ['view'],
  },
  {
    key: 'created_at',
    type: 'datetime',
    label: 'Dibuat pada',
    sortable: true,
    visibility: ['view'],
  },
]
