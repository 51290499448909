export default [
  {
    key: 'id',
    type: 'id',
    label: 'ID',
  },
  {
    key: 'image_url',
    type: 'image',
    label: 'Banner',
  },
  {
    key: 'name',
    label: 'Nama',
  },
  {
    key: 'is_active',
    label: 'Active',
    filter: {
      key: 'is_active',
      type: 'select',
      label: 'Status',
      options: [
        { value: true, text: 'Aktif' },
        { value: false, text: 'Tidak aktif' },
      ],
    },
  },
  {
    key: 'url',
    type: 'url',
  },
]
