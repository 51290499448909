import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/id'

moment.locale('id')

Vue.filter('number', value => Number(value).toLocaleString())
Vue.filter('date', value => moment.utc(value).local().format('DD/MM/YYYY'))
Vue.filter('datetime', value => moment.utc(value).local().format('DD/MM/YYYY HH:mm'))
Vue.filter('dateShort', value => moment.utc(value).local().format('MMM Do'))
Vue.filter('dateFromNow', value => moment.utc(value).local().fromNow())

Vue.filter('thumbnail', (value, size = 32) => {
  // check whether the image is empty or from source other than imagekit CDN
  if (!value || !value.includes('imagekit.io')) {
    return value
  }

  return `${value}?tr=w-${size},h-${size},cm-pad_resize`
})

Vue.filter('phone', phone => (phone.length >= 12 && phone.length <= 13
  ? phone.replace(/(\d{2})(\d{3})(\d{4})(\d{4})/, '+$1 $2-$3-$4')
  : `+${phone}`))

Vue.filter('table-filter', (value, size = 32) => {
  let image = value

  // check whether the image is empty or from source other than google CDN
  if (!image || !image.includes('googleusercontent')) {
    return image
  }

  // check whether the image resized already
  if (image.includes('=s')) {
    [image] = image.split('=s')
  }

  return `${image}=s${size}-c`
})

Vue.filter('rupiah', value => new Intl.NumberFormat('id-ID', {
  style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0,
}).format(value).replace(',00', ''))

Vue.filter('rupiahShort', value => {
  if (value >= 1e9) {
    return `${new Intl.NumberFormat('id-ID', {
      style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0,
    }).format(value / 1e9).replace(',00', '')}B`
  } if (value >= 1e6) {
    return `${new Intl.NumberFormat('id-ID', {
      style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0,
    }).format(value / 1e6).replace(',00', '')}M`
  } if (value >= 1e3) {
    return `${new Intl.NumberFormat('id-ID', {
      style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0,
    }).format(value / 1e3).replace(',00', '')}K`
  }
  return new Intl.NumberFormat('id-ID', {
    style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0,
  }).format(value).replace(',00', '')
})
