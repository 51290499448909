import { get, set } from '@/helper/localStorage'
import _get from 'lodash/get'
import fields from '@/field/table/collection'

export default {
  namespaced: true,
  state: {
    tableFields: get('collectionTableFields', fields),
    tableData: [],
    tablePagination: get('collectionTablePagination'),
    tableKeyword: get('collectionTableKeyword'),
    tableSort: get('collectionTableSort'),
    tableFilter: get('collectionTableFilter', []),
    detailData: get('collectionDetailData'),
    loadingTable: false,
    loadingDetail: false,
    loadingSubmit: false,
  },
  getters: {
    tableFields: state => {
      if (!state.tableFields) {
        return undefined
      }

      // only the visibility is customizable by the user
      const visibilities = state.tableFields.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.visibility }), {},
      )

      // the other props uses the default fields configuration
      return fields.map(item => ({
        ...item,
        // only replace the visibility value if user override it
        visibility: item.key in visibilities ? visibilities[item.key] : item.visibility,
      }))
    },
    tableSort: state => {
      if (!state.tableSort) {
        return undefined
      }

      const symbol = state.tableSort.direction === 'desc' ? '-' : ''

      return `${symbol}${state.tableSort.key}`
    },
    tableData: state => {
      if (!state.tableData) {
        return []
      }

      return state.tableData.map(item => ({
        ...item,
        // add or replace field here
      }))
    },
    detailData: state => {
      if (!state.detailData) {
        return undefined
      }

      return {
        ...state.detailData,
        // add or replace field here
      }
    },
    tableFilter: state => {
      if (!state.tableFilter) {
        return []
      }

      return state.tableFilter
        .reduce((acc, elem) => Object.assign(acc, {
          [`filter[${elem.key}]`]: Array.isArray(elem.value)
            ? elem.value.join(',')
            : elem.value,
        }), {})
    },
    tableParams: (state, getters, rootState) => ({
      'filter[keyword]': state.tableKeyword,
      ...getters.tableFilter,
      sort: getters.tableSort,
      page: _get(state, 'tablePagination.current_page', 1),
      limit: rootState.appConfig.settings.tablePerPage,
    }),
  },
  mutations: {
    SET_TABLE_FIELDS(state, val) {
      set('collectionTableFields', val)
      state.tableFields = val
    },
    RESET_TABLE_FIELDS(state) {
      set('collectionTableFields', fields)
      state.tableFields = fields
    },
    SET_TABLE_DATA(state, val) {
      state.tableData = val
    },
    SET_TABLE_PAGINATION(state, val) {
      set('collectionTablePagination', val)
      state.tablePagination = val
    },
    SET_TABLE_KEYWORD(state, val) {
      set('collectionTableKeyword', val)
      state.tableKeyword = val
    },
    SET_DETAIL_DATA(state, val) {
      set('collectionDetailData', val)
      state.detailData = val
    },
    SET_TABLE_SORT(state, val) {
      set('collectionTableSort', val)
      state.tableSort = val
    },
    SET_TABLE_FILTER(state, val) {
      set('collectionTableFilter', val)
      state.tableFilter = val
    },
    SET_LOADING_TABLE(state, val) {
      state.loadingTable = val
    },
    SET_LOADING_DETAIL(state, val) {
      state.loadingDetail = val
    },
    SET_LOADING_SUBMIT(state, val) {
      state.loadingSubmit = val
    },
  },
  actions: {
    getTableData({ commit, getters }) {
      commit('SET_LOADING_TABLE', true)

      const { tableParams: params } = getters
      this._vm.$http.get('/v1/admin/collections', { params })
        .then(({ data }) => {
          commit('SET_TABLE_DATA', data.data)
          commit('SET_TABLE_PAGINATION', data.meta)
        })
        .finally(() => {
          commit('SET_LOADING_TABLE', false)
        })
    },
    getDetail({ commit }, id) {
      commit('SET_LOADING_DETAIL', true)

      this._vm.$http.get(`/v1/admin/collections/${id}`)
        .then(({ data }) => {
          commit('SET_DETAIL_DATA', data.data)
        })
        .finally(() => {
          commit('SET_LOADING_DETAIL', false)
        })
    },
    create({ commit }, data) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.post('/v1/admin/collections', data)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    update({ commit }, { id, data }) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.patch(`/v1/admin/collections/${id}`, data)
        .then(res => {
          commit('SET_DETAIL_DATA', res.data.data)
        })
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
    delete({ commit }, id) {
      commit('SET_LOADING_SUBMIT', true)

      return this._vm.$http.delete(`/v1/admin/collections/${id}`)
        .finally(() => {
          commit('SET_LOADING_SUBMIT', false)
        })
    },
  },
}
