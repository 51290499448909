import address from './address'
import cart from './cart'
import user from './user'
import banner from './banner'
import category from './category'
import transaction from './transaction'
import settings from './settings'
import voucher from './voucher'
import product from './product'
import rootCategory from './root-category'
import parentCategory from './parent-category'
import favoriteCollections from './favorite-collections'
import favorite from './favorite'
import brand from './brand'
import paymentMethod from './payment-method'
import collection from './collection'
import questionCategory from './question-category'
import question from './question'
import sale from './sale'

export default {
  address,
  cart,
  user,
  banner,
  category,
  transaction,
  settings,
  voucher,
  product,
  rootCategory,
  parentCategory,
  favoriteCollections,
  favorite,
  brand,
  paymentMethod,
  collection,
  questionCategory,
  question,
  sale,
}
